import {
    createContext,
    Dispatch,
    memo,
    ReactNode,
    SetStateAction,
    useContext,
    useEffect,
    useState,
} from 'react';

import { mapDestinations } from '@/common/service/api/Destination/Destination';
import { IDestination } from '@/entities/Destination/domain/Destination.domain';
import { ICategory } from '@/common/service/api/Categories/Categories.domain';
import destinationsCache from 'scripts/cache/destinations.json';
import categoriesCache from 'scripts/cache/categoriesList.json';

type ProviderProps = {
    children: ReactNode | undefined;
};

type ContextProps = {
    destinations: IDestination[];
    categories: ICategory[];
    isOpenPromo?: boolean;
    setIsOpenPromo?: Dispatch<SetStateAction<boolean>>;
};

const Context = createContext<ContextProps>({
    destinations: [],
    categories: [],
});

const EXP_TIME = 1715313600000;

const ContextProvider = ({ children }: ProviderProps) => {
    const destinations = mapDestinations(destinationsCache);
    const categories: ICategory[] = categoriesCache.sort((a, b) => {
        if (a.name > b.name) {
            return 1;
        }
        if (a.name < b.name) {
            return -1;
        }
        return 0;
    });

    const [isOpenPromo, setIsOpenPromo] = useState(false);

    useEffect(() => {
        setIsOpenPromo(new Date().getTime() < EXP_TIME);
    }, []);

    return (
        <Context.Provider
            value={{
                destinations,
                categories,
                isOpenPromo,
                setIsOpenPromo,
            }}
        >
            {children}
        </Context.Provider>
    );
};

export const AppProvider = memo(ContextProvider);
export const useAppContext = () => {
    const context = useContext(Context);

    if (!context) {
        throw new Error('useAppContext must be used within AppContext');
    }

    return context;
};
