export enum CookiesTypes {
    CS_PROMOCODE = 'ts_promocode',
    CS_SUBSCRIBE_TM = 'ts_sub_tm',
    CS_TOKEN_CART = 'ts_token_cart',
    CS_DISABLE_ANALYTICS = 'ts_disable_analytics',
    FBUY_CODE = 'ts_fbuy_code',
    CS_METAMASK_WALLET = 'ts_metamask_wallet',
    AFFILIATES_CODE = 'ts_aff_code',
    AFFILIATES_DATA = 'ts_aff_data',
    GCLID_CODE = 'ts_gclid',
    CS_CART_ACTIVITIES = 'ts_cart_activities',
    CS_IN_LS = '_tsinls',
    CS_THEME_MODE = '_ts__theme-mode',
    CS_USER = 'user',
    CS_TERMS = 'ts_terms',
    CS_CRS = 'ts_crs',
    CS_CRS_UTM = 'ts_crs_utm_data',
}

export enum LocalStorageTypes {
    LS_STATE = '_ts__state',
    LS_TOKEN_CART = '_ts__state-token-cart',
    LS_WISH_LIST = '_ts__state-wish-list',
    LS_CONTACT_INFO = '_ts__contact-info',
    LS_CART_ITEM_PRODUCT_IDS = '_ts__cart-item-product-ids',
    LS_COGNITO_USER = '_ts__cognito-user',
    LS_COGNITO_GLOBAL_LOADING = '_ts__cognito-global-loading',
    LS_COGNITO_SIGN_OUT = '_ts__cognito-sign-out',
    LS_COGNITO_REDIRECT = '_ts__cognito-redirect',
    LS_COGNITO_SIGN_UP = '_ts__cognito-sign-up',
    LS_RECENT_SEARCH = 'recent_search',
    LS_THEME_MODE = '_ts__theme-mode',
    LS_USER_ID = '__anon_id',
    LS_PROMOCODE = '_ts__promocode',
    LS_CART_ACTIVITY = '_ts__cart_activity',
    LS_CART_ACTIVITIES = '_ts__cart_activities',
    LS_TEMP_DETAILS_DATA = '_ts__temp_details_data',
}

export enum SessionStorageType {
    LAST_DESTINATION = '_ts__last_destination',
    CLICK_POSITION = '_ts__click_position',
    HISTORY = '_ts__history',
    CART_ACTIVITIES = '_ts__cart_activities',
    EDIT_MODE = '_ts__edit_mode',
    SEARCH_LIST = '_ts__search_list',
    TEMP_PROMOTION_CLICK = '_ts__temp_promotion_click',
    PROMOTION_NAMES = '_ts__promotion_names_list',
}

export type TPath = {
    path?: string;
    expires?: Date;
};

export type TCookieData = string | number | object | undefined;
