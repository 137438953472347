import {
    createContext,
    Dispatch,
    PropsWithChildren,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { TDate, useDateContext } from '@/common/app/contexts/DateContext';
import { mergeAvailability } from '@/common/app/utils/mergeAvailability';
import { IDateRange } from '@/common/domain/Date.domain';
import { IActivityWithAvailability } from '@/common/domain/Merge.domain';
import { IActivityItem, IFullList } from '@/common/service/api/Activity/Activity.domain';
import { ICategory } from '@/common/service/api/Categories/Categories.domain';
import { IPartnersItem } from '@/common/service/api/Partners/Partners.domain';
import {
    IDestination,
    IDestinationCategory,
    IDestinationList,
} from '@/entities/Destination/domain/Destination.domain';
import { useRouter } from 'next/router';

import { getAvailabilityShortByIds } from '@/entities/Attractions/service/ApiAttractionsPage';
import { CreateParams, splitAvailAndSoldout } from '@/entities/Attractions/service/Creators';
import {
    getSortFunction,
    OPTIONS,
    SORT_OPTIONS,
    sortByBestMatch,
} from '@/entities/Attractions/service/SortFunctions';

import { getRecommends } from '@/shared/FeaturedActivities/service/ApiFeaturedActivities';
import { useCartContext } from '.';
import { loadCookiePromocode, saveCookiePromocode } from '@/common/service/storage';
import { filterByAttribution, parseAttr } from '../constants/attributions';
import { getPromocodeName } from '@/common/service/api/Promocodes/Promocode';
import { IRecommendsItem } from '@/shared/FeaturedActivities/ui/featuredActivitiesPreview/FeaturedActivitiesPreview.types';
import { getNormalisedDateByQuery } from '../utils/dateUtils';

const TRIPS_CHUNK_COUNT = 14;

type AttractionsProviderProps = PropsWithChildren<{
    fullList?: IFullList;
    firstTenActivities?: IActivityWithAvailability[];
    currentDestination?: IDestinationList;
    currentCategory?: IDestinationCategory;
    noRecommends?: boolean;
}>;

export interface IAttractionsSearch {
    destination: IDestination;
    category?: ICategory;
    partner?: IPartnersItem;
    dateRange: IDateRange;
}

export interface IDestinationPartners {
    loading: boolean;
    items: IPartnersItem[];
    destination_id?: string;
}

interface IAttractionState {
    defaultIds: string;
    showList: IActivityWithAvailability[];
    soldOutList: IActivityWithAvailability[];
    recommends: IRecommendsItem[] | null;
    soldOutListRecommends: IRecommendsItem[] | null;
}

interface IAttractionLoadingState {
    initLoading: boolean;
    recommendsLoading: boolean;
    changeLoading: boolean;
    initChangeLoading: boolean;
    paginationLoading: boolean;
}

interface IAttractionSettingsState {
    categories: string[];
    attributes: number[];
    sorting: (typeof OPTIONS)[number];
    initDate: TDate;
}

type ContextProps = {
    state: IAttractionState;
    setState: Dispatch<SetStateAction<IAttractionState>>;
    loading: IAttractionLoadingState;
    setLoading: Dispatch<SetStateAction<IAttractionLoadingState>>;
    settings: IAttractionSettingsState;
    setSettings: Dispatch<SetStateAction<IAttractionSettingsState>>;

    totalLength: number;
    showedLenght: number;
    allProcessedLenght: number;
    isComplete: boolean;
    onScrollPage: (data: { count?: number }) => Promise<void>;

    showMoreText: string;
    availableAttributesIds: number[];
    isSoldOut: boolean;
    normalisedList: IActivityItem[];
};

const AttractionsContext = createContext<ContextProps | null>(null);

export const AttractionsProvider = ({ children, ...props }: AttractionsProviderProps) => {
    const { date } = useDateContext();
    const { runToast } = useCartContext();
    const { query, isReady } = useRouter();

    const normalisedDate = useMemo(() => {
        return getNormalisedDateByQuery({ query, date });
    }, [query, date]);

    const [state, setState] = useState<IAttractionState>({
        defaultIds: '',
        recommends: null,
        soldOutListRecommends: null,
        showList: props.fullList?.items.slice(0, TRIPS_CHUNK_COUNT) || [],
        soldOutList: [],
    });

    const [loading, setLoading] = useState<IAttractionLoadingState>({
        initLoading: true,
        recommendsLoading: true,
        paginationLoading: false,
        changeLoading: false,
        initChangeLoading: false,
    });

    const [settings, setSettings] = useState<{
        categories: string[];
        attributes: number[];
        sorting: (typeof OPTIONS)[number];
        initDate: TDate;
    }>({
        sorting: OPTIONS[0],
        attributes: [],
        categories: [],
        initDate: normalisedDate,
    });

    const normalisedList = useMemo(() => {
        if (!props.fullList) return [];

        const filteredByCategories = settings.categories.length
            ? props.fullList.items.filter((i) =>
                  i.activity_categories.some((id) => settings.categories.includes(id))
              )
            : props.fullList.items;
        const filteredByAttributes = filterByAttribution({
            trips: filteredByCategories,
            attributes: settings.attributes,
        });

        const isDefaultSort = settings.sorting === SORT_OPTIONS.bestMatch;
        const sortFunc = getSortFunction(settings.sorting);

        return isDefaultSort
            ? sortByBestMatch(filteredByAttributes, props.fullList.activity_ids || '')
            : [...filteredByAttributes].sort(sortFunc);
    }, [props.fullList, settings.attributes, settings.categories, settings.sorting]);

    const availableAttributesIds = useMemo(() => {
        const ids: number[] = [];
        normalisedList.forEach((trip) => {
            if (trip.attributes) {
                const attrs = parseAttr(trip.attributes);
                ids.push(...attrs.filter((i) => !ids.includes(i)));
            }
        });
        return ids;
    }, [normalisedList]);

    const isSoldOut = useMemo(
        () => Boolean(props.fullList && props.fullList.items.length === state.soldOutList.length),
        [props.fullList, state.soldOutList]
    );

    const { totalLength, showedLenght, allProcessedLenght, isComplete, showMoreText } =
        useMemo(() => {
            return {
                totalLength: normalisedList.length,
                showedLenght: state.showList.length,
                allProcessedLenght: state.showList.length + state.soldOutList.length,
                isComplete:
                    state.showList.length + state.soldOutList.length >= normalisedList.length,
                showMoreText: `Show ${
                    normalisedList.length - state.showList.length
                } more tours & activities`,
            };
        }, [normalisedList.length, state.showList.length, state.soldOutList.length]);

    const onScrollPage = useCallback(
        async ({ count, newStartPoint }: { count?: number; newStartPoint?: number }) => {
            setLoading((prev) => ({
                ...prev,
                paginationLoading: true,
            }));

            const { showList, soldOutList } = state;

            const startPoint = newStartPoint || showList.length + soldOutList.length;
            const lastPoint = startPoint + TRIPS_CHUNK_COUNT;
            const newList = normalisedList.slice(startPoint, lastPoint);

            const [activity_ids, params] = CreateParams(newList, normalisedDate);

            const availability = await getAvailabilityShortByIds(activity_ids, params);

            const newActivities = mergeAvailability(newList, availability);
            const { available, soldout } = splitAvailAndSoldout(newActivities);

            setState((prev) => ({
                ...prev,
                showList: [...prev.showList, ...available],
                soldOutList: [...prev.soldOutList, ...soldout],
            }));

            const currentLength = startPoint + available.length + soldout.length;

            if (available.length < 8 && (count || 0) < 8 && currentLength < normalisedList.length) {
                onScrollPage({
                    count: (count || 0) + available.length,
                    newStartPoint: currentLength,
                });
                return;
            }

            setLoading((prev) => ({
                ...prev,
                paginationLoading: false,
            }));
        },
        [normalisedDate, normalisedList, state]
    );

    const initRecommends = useCallback(
        async ({ promo, date }: { promo?: string; date: TDate }) => {
            if (props.noRecommends || !props.currentDestination?.id) return;

            const recommends = await getRecommends({
                destination_id: props.currentDestination.id,
                category_id: props.currentCategory?.id,
                ...date,
                ...(promo && { promocode: promo as string }),
            });

            setState((prev) => ({
                ...prev,
                recommends: recommends || [],
            }));

            setLoading((prev) => ({
                ...prev,
                recommendsLoading: false,
            }));
        },
        [props.noRecommends, props.currentDestination?.id, props.currentCategory?.id]
    );

    const initAvailability = useCallback(
        async ({ promo, date, list }: { promo?: string; date: TDate; list: IActivityItem[] }) => {
            const firstTrips = list.slice(0, TRIPS_CHUNK_COUNT);
            const [activity_ids, params] = CreateParams(firstTrips, date, promo);

            const [availability, promocode] = await Promise.all([
                getAvailabilityShortByIds(activity_ids, params),
                promo ? await getPromocodeName(promo) : undefined,
            ]);

            if (promocode?.name) {
                saveCookiePromocode(promocode.name);
                runToast?.('promocode', {
                    promoName: promocode.name || '',
                });
            }

            const newActivities = mergeAvailability(firstTrips, availability);
            const { available, soldout } = splitAvailAndSoldout(newActivities);

            setState((prev) => ({
                ...prev,
                showList: available,
                soldOutList: soldout,
            }));

            setLoading((prev) => ({
                ...prev,
                initLoading: !available.length,
            }));

            if (available.length < 10 && list.length > availability.length) {
                onScrollPage({ count: available.length, newStartPoint: availability.length });
            }
        },
        [runToast, onScrollPage]
    );

    const initSoldOut = useCallback(
        async ({ promo, date }: { promo?: string; date: TDate }) => {
            if (!props.currentDestination?.id || !props.currentCategory?.id) return;

            const recommends = await getRecommends({
                destination_id: props.currentDestination.id,
                ...date,
                ...(promo && { promocode: promo as string }),
            });

            setState((prev) => ({
                ...prev,
                soldOutListRecommends: recommends || [],
            }));

            setLoading((prev) => ({
                ...prev,
                initLoading: false,
            }));
        },
        [props.currentDestination?.id, props.currentCategory?.id]
    );

    const changeParams = useCallback(
        async ({ promo, date, list }: { promo?: string; date: TDate; list: IActivityItem[] }) => {
            const firstTrips = list.slice(0, TRIPS_CHUNK_COUNT);
            const [activity_ids, params] = CreateParams(firstTrips, date, promo);

            const availability = await getAvailabilityShortByIds(activity_ids, params);

            const newActivities = mergeAvailability(firstTrips, availability);
            const { available, soldout } = splitAvailAndSoldout(newActivities);

            setState((prev) => ({
                ...prev,
                showList: available,
                soldOutList: soldout,
            }));

            setLoading((prev) => ({
                ...prev,
                changeLoading: false,
            }));

            if (available.length < 8 && list.length > availability.length) {
                onScrollPage({ count: available.length, newStartPoint: availability.length });
            }
        },
        [onScrollPage]
    );

    useEffect(() => {
        if (!isReady || !props.fullList || !normalisedList.length) return;

        const isNewPage = state.defaultIds !== props.fullList.activity_ids;
        const isNewDate =
            normalisedDate.from !== settings.initDate.from ||
            normalisedDate.to !== settings.initDate.to;

        const oldPromo = loadCookiePromocode();
        const promo =
            typeof query.promo !== 'string' ? oldPromo : query.promo.toString().toLowerCase();

        if (isNewPage || isNewDate) {
            setState({
                defaultIds: props.fullList.activity_ids || '',
                recommends: null,
                soldOutListRecommends: null,
                showList: props.fullList.items.slice(0, TRIPS_CHUNK_COUNT),
                soldOutList: [],
            });
            setLoading({
                initLoading: true,
                recommendsLoading: true,
                paginationLoading: false,
                changeLoading: false,
                initChangeLoading: false,
            });
            setSettings((prev) =>
                isNewPage
                    ? {
                          sorting: OPTIONS[0],
                          attributes: [],
                          categories: [],
                          initDate: normalisedDate,
                      }
                    : {
                          ...prev,
                          initDate: normalisedDate,
                      }
            );
            initRecommends({ promo, date: normalisedDate });
            initAvailability({ promo, date: normalisedDate, list: normalisedList });
        }

        if (loading.initChangeLoading) {
            setLoading((prev) => ({
                ...prev,
                initChangeLoading: false,
            }));
            changeParams({ promo, date: settings.initDate, list: normalisedList });
        }

        if (isSoldOut && loading.initLoading) {
            initSoldOut({ promo, date: normalisedDate });
        }
    }, [
        normalisedDate,
        initAvailability,
        initRecommends,
        changeParams,
        initSoldOut,
        isSoldOut,
        isReady,
        normalisedList,
        props.fullList,
        query.promo,
        settings.initDate,
        state.defaultIds,
        loading.initChangeLoading,
        loading.initLoading,
    ]);

    return (
        <AttractionsContext.Provider
            value={{
                state,
                setState,
                loading,
                setLoading,
                settings,
                setSettings,
                totalLength,
                showedLenght,
                allProcessedLenght,
                isComplete,
                onScrollPage,
                showMoreText,
                availableAttributesIds,
                isSoldOut,
                normalisedList,
            }}
        >
            {children}
        </AttractionsContext.Provider>
    );
};

export const useAttractionsContext = () => {
    const context = useContext(AttractionsContext);

    if (!context) {
        throw new Error('Context must be used within a Context Provider');
    }

    return context;
};
